<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row ">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in articles" :key="index">
                    <div class="blog-card style1">
                        <div class="blog-img">
                            <img :src="getUrlApiForFiles(article.urlImage)" alt="Image" style="width: 100%; height:auto; object-fit: contain;">
                        </div>
                        <div class="blog-info">
                            <ul class="blog-metainfo  list-style">
                                <li><i class="flaticon-user"></i> <router-link to="/posts-by-author">La Poste du Bénin SA</router-link></li>
                                <li><i class="flaticon-calendar"></i>{{ format_date(article.datePublication)  }}</li>
                            </ul>
                            <h3><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{ article.titre }}</router-link></h3>
                            <div v-html="article?.contenu"></div>
                            <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }" class="link style1">Lire plus
                                <i class="flaticon-right-arrow"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
    name: 'TransfertArgent',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   

    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/13`)
        .then(({ data }) => {
          articles.value = data.data.data;
          console.log(articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }
    
    onMounted(() => {
        getTreeArticle();
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_date
    };
  },
});
</script>