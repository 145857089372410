<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in articles" :key="index">
                    <div class="blog-card style1">
                        <div class="blog-img"  >
                            <iframe
                            :src="getYoutubeUrl(article?.source)"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div class="blog-info">
                            <h3><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{ article.titre }}</router-link></h3>
                            <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }" class="link style text-center" style="text-align: center;">Afficher la vidéo                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";
import { useRoute } from "vue-router";

export default defineComponent({
    name: 'MediathequePosteVideo',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   
    const route = useRoute();
    const totalMedias = ref([]);
    const videoUrl = ref()

    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/68`)
        .then(({ data }) => {
          articles.value = data.data.data;
          console.log('articles', articles)
          console.log(articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }
    
    // function getAllMedia(id: any, index: number) {
    //     return ApiService.get(`/articles/medias/` + id)
    //     .then(({ data }) => {
    //         totalMedias.value[index] = data.data.length;
    //         return data;
    //     })
    //     .catch(({ response }) => {
    //         error(response.message)
    //     });
    // }

    onMounted(async () => {
            await getTreeArticle();
            //await Promise.all(articles.value.map((article, index) => getAllMedia(article.id, index)));
        });

        function getYoutubeUrl(source) {
        if (!source) return ''; 
        // const videoId = videoUrl.value; 
        return `https://www.youtube.com/embed/${source}`;
        }
       
    return { 
        articles,
        getUrlApiForFiles,
        format_date,
        route,totalMedias,
        getYoutubeUrl,

    };
  },
});
</script>