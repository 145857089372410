<template>
  <div class="service-wrap ptb-100">
    <div class="container">
      <div class="row ">
        <div class="col-sm-3"><MenuProduit/></div>
        <div class="col-sm-9">
          <div class="table-responsive-sm">
            <table class="table table-bordered ">
              <thead class="">
                <tr>
                  <th class="ttdbackground">Intervalle du Poids en gramme</th>
                  <th class="tablebackground" v-for="header in distances" :key="header">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(montant, inde) in montants" :key="inde">
                  <td class="ttdbackground">{{ inde }}</td>
                  <td v-for="dis in distances" :key="dis">{{ separateur(montant[dis]) || 'N/A' }}F CFA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error, format_date, separateur } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import  MenuProduit  from "../Services/MenuProduit.vue";

export default defineComponent({
  name: 'ProduitPosteLettre',
  components: {
    MenuProduit
  },
  setup() {
    const distances = ref([]); // Example headers
    const poids = ref([]); // Should match the headers except 'Student'
    const montants = ref([]);

    const fetchStudents = async () => {
      ApiService.get(`/poidsDistanceMontants/1/3`)
        .then(({ data }) => {
          montants.value = data.data.data;
          distances.value = data.data.distances;
          poids.value = data.data.poids;
          console.log(montants.value, poids.value, distances.value, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        })
        .catch(({ response }) => {
          console.log(response, "8888888888888888888888888888888888888888");
          error(response.message)
        });
    };

    onMounted(() => {
      fetchStudents();
    });

    return {
      montants,
      distances,
      poids,
      separateur
    };
    //Tout ce qu'on fait dans la vie raisonne dans eternité
  }
});
</script>
<style scoped>

.tablebackground {
  background-color: #f3c148;
  color: #fff !important;
  /* padding-top: 40px;
     padding-bottom: 40px; */
}

.ttdbackground {
  background-color: #0a59a4 !important;
  color: #fff !important;
}
</style>