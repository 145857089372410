<template>
  <div class="service-wrap style3 ptb-100 bg-rock">
    <div class="container">
      <img
        src="../../assets/images/service-shape-1.png"
        alt="Image"
        class="service-shape-one"
      />
      <img
        src="../../assets/images/service-shape-2.png"
        alt="Image"
        class="service-shape-two"
      />
      <div class="section-title style1 text-center mb-40">
        <span>Nos Services</span>
        <h2 class="text-white">Services offerts par<br>La Poste du Bénin SA</h2>
      </div>
      <div class="row gx-5 align-items-center">
        <div class="col-md-4" v-for="categorieArticle in CategorieArticles" :key="categorieArticle.id">
          <div class="service-card style3" >
            <span class="service-icon">
              <i class="flaticon-payment-method"></i>
            </span>
            <div class="service-info" style="display: flex !important;vertical-align: middle;align-items: center !important;">
              <h3 style="margin-bottom: 0px !important; line-height: 28px !important;">
                <router-link :to=categorieArticle.lienExterne v-if="categorieArticle.lienExterne">{{ categorieArticle.nom }}</router-link>
                <router-link to="#" v-else>{{ categorieArticle.nom }}</router-link>
              </h3>
            </div>
            <div class="service-info"  style="margin-left: 0px !important; width: calc(100%) !important">
              <p>
                {{ (categorieArticle.description.length > 25) ? categorieArticle.description.substring(0, 90) + '...' : categorieArticle.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-20">
        <router-link to="/services" class="btn style1"
          >Voir plus</router-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { onMounted, ref } from "vue";
import axios from 'axios';
import { Faq } from "../../models/Faq";
import ApiService from '@/services/ApiService';

export default {
    name: "ServicesPoste",
    components: {},
    setup() {
        const CategorieArticles = ref([]);

        function getAllService() {
          return ApiService.get('/categorieArticleChilds')
              .then(response => {
                  const categorieArticleIsService = response.data.data.filter(category => category.isService == "Oui");
                  // const categorieArticleIsService = response.data.data;

                  CategorieArticles.value = categorieArticleIsService;
                  console.log("Catgeoriearticle", categorieArticleIsService)
                  return categorieArticleIsService;
              })
              .catch(error => {
                  // Gérer l'erreur
              });
        }

        
    onMounted(() => {
      getAllService();
    });


        return {
            CategorieArticles,
            getAllService
        };
    }
}
</script>
