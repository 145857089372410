import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";

import HomeOnePage from "@/components/Pages/HomeOnePage.vue";
import HomeTwoPage from "@/components/Pages/HomeTwoPage.vue";
import HomeThreePage from "@/components/Pages/HomeThreePage.vue";
import AboutPage from "@/components/Pages/AboutPage.vue";
import ServicesPage from "@/components/Pages/ServicesPage.vue";
import ServicesTwoPage from "@/components/Pages/ServicesTwoPage.vue";
import ServicesDetailsPage from "@/components/Pages/ServicesDetailsPage.vue";
import ProjectPage from "@/components/Pages/ProjectPage.vue";
import ProjectDetailsPage from "@/components/Pages/ProjectDetailsPage.vue";
import TeamPage from "@/components/Pages/TeamPage.vue";
import AccountPage from "@/components/Pages/AccountPage.vue";
import ApplyPage from "@/components/Pages/ApplyPage.vue";
import PricingPage from "@/components/Pages/PricingPage.vue";
import FaqPage from "@/components/Pages/FaqPage.vue";
import FaqEservicePage from "@/components/Pages/FaqEservicePage.vue";
import TestimonialsPage from "@/components/Pages/TestimonialsPage.vue";
import MyAccountPage from "@/components/Pages/MyAccountPage.vue";
import LoginPage from "@/components/Pages/LoginPage.vue";
import RegisterPage from "@/components/Pages/RegisterPage.vue";
import RecoverPasswordPage from "@/components/Pages/RecoverPasswordPage.vue";
import TermsOfServicePage from "@/components/Pages/TermsOfServicePage.vue";
import PrivacyPolicyPage from "@/components/Pages/PrivacyPolicyPage.vue";
import ErrorPage from "@/components/Pages/ErrorPage.vue";
import BlogPage from "@/components/Pages/BlogPage.vue";
import BlogLeftSidebarPage from "@/components/Pages/BlogLeftSidebarPage.vue";
import BlogRightSidebarPage from "@/components/Pages/BlogRightSidebarPage.vue";
import BlogDetailsPage from "@/components/Pages/BlogDetailsPage.vue";
import BlogDetailsLeftSidebarPage from "@/components/Pages/BlogDetailsLeftSidebarPage.vue";
import BlogDetailsRightSidebarPage from "@/components/Pages/BlogDetailsRightSidebarPage.vue";
import ContactUsPage from "@/components/Pages/ContactUsPage.vue";

import ColisPostauxPage from "@/components/Pages/ColisPostauxPage.vue";
import EnvoiExpressPage from "@/components/Pages/EnvoiExpressPage.vue";
import PhiLateliePage from "@/components/Pages/PhilateliePage.vue";
import PosteLettrePage from "@/components/Pages/PosteLettrePage.vue";
import ServiceDistributionPage from "@/components/Pages/ServiceDistributionPage.vue";
import AssurancePage from "@/components/Pages/AssurancePage.vue";
import CentreChequePage from "@/components/Pages/CentreChequePage.vue";
import CaisseNationalPage from "@/components/Pages/CaisseNationalPage.vue";
import TransfertArgentPage from "@/components/Pages/TransfertArgentPage.vue";
import ServicePublicPage from "@/components/Pages/ServicePublicPage.vue";
import ServicePostePage from "@/components/Pages/ServicePostePage.vue";
import AutreServicePage from "@/components/Pages/AutreServicePage.vue";
import PosteVertePage from "@/components/Pages/PosteVertePage.vue";
import PosteVoyagePage from "@/components/Pages/PosteVoyagePage.vue";
import ProduitPartenariatPage from "@/components/Pages/ProduitPartenariatPage.vue";

import ActualitePostePage from "@/components/Pages/ActualitePostePage.vue";
import MediathequePostePage from "@/components/Pages/MediathequePostePage.vue";
import MediathequePosteVideoPage from "@/components/Pages/MediathequePosteVideoPage.vue";
import DocumentPostePage from "@/components/Pages/DocumentPostePage.vue";
import ViewMediathequePage from "@/components/Pages/ViewMediathequePage.vue";
import ViewArticlePage from "@/components/Pages/ViewArticlePage.vue";
import PreviewArticlePage from "@/components/Pages/PreviewArticlePage.vue";
import SimulationPage from "@/components/Pages/SimulationPage.vue";

import MentionLegalPage from "@/components/Pages/MentionLegalePage.vue";
import DonnePersonnellePage from "@/components/Pages/DonnePersonnellePage.vue";
import CooKiePage from "@/components/Pages/CooKiePage.vue";

import ProduitTransfertPage from "@/components/Pages/ProduitTransfertPage.vue";
import ProduitColisPostauxPage from "@/components/Pages/ProduitColisPostauxPage.vue";
import ProduitPosteLettrePage from "@/components/Pages/ProduitPosteLettrePage.vue";
import ProduitEnvoisExpressPage from "@/components/Pages/ProduitEnvoisExpressPage.vue";
import ProduitTransportLocationPage from "@/components/Pages/ProduitTransportLocationPage.vue";


const routes = [
  { path: "/", name: "HomeOnePage", component: HomeOnePage },
  { path: "/home-two", name: "HomeTwoPage", component: HomeTwoPage },
  { path: "/home-three", name: "HomeThreePage", component: HomeThreePage },
  { path: "/about", name: "AboutPage", component: AboutPage },
  { path: "/services", name: "ServicesPage", component: ServicesPage },
  {
    path: "/services-two",
    name: "ServicesTwoPage",
    component: ServicesTwoPage,
  },
  {
    path: "/service-details",
    name: "ServicesDetailsPage",
    component: ServicesDetailsPage,
  },
  { path: "/projects", name: "ProjectPage", component: ProjectPage },
  {
    path: "/project-details",
    name: "ProjectDetailsPage",
    component: ProjectDetailsPage,
  },
  { path: "/team", name: "TeamPage", component: TeamPage },
  { path: "/open-account", name: "AccountPage", component: AccountPage },
  { path: "/apply-loan", name: "ApplyPage", component: ApplyPage },
  { path: "/pricing", name: "PricingPage", component: PricingPage },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  { path: "/faqeservice", name: "FaqEservicePage", component: FaqEservicePage },
  
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  { path: "/my-account", name: "MyAccountPage", component: MyAccountPage },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  {
    path: "/recover-password",
    name: "RecoverPasswordPage",
    component: RecoverPasswordPage,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfServicePage",
    component: TermsOfServicePage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  {
    path: "/blog-left-sidebar",
    name: "BlogLeftSidebarPage",
    component: BlogLeftSidebarPage,
  },
  {
    path: "/blog-right-sidebar",
    name: "BlogRightSidebarPage",
    component: BlogRightSidebarPage,
  },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/blog-details-left-sidebar",
    name: "BlogDetailsLeftSidebarPage",
    component: BlogDetailsLeftSidebarPage,
  },
  {
    path: "/blog-details-right-sidebar",
    name: "BlogDetailsRightSidebarPage",
    component: BlogDetailsRightSidebarPage,
  },
  {
    path: "/view-article/:id",
    name: "ViewArticlePage",
    component: ViewArticlePage,
  },
  {
    path: "/preview-article/:id",
    name: "PreviewArticlePage",
    component: PreviewArticlePage,
  },
  {
    path: "/view-mediatheque/:id",
    name: "ViewMediathequePage",
    component: ViewMediathequePage,
  },
  { path: "/contact", name: "ContactUsPage", component: ContactUsPage },

  //Services postaux
  { path: "/colisPostaux", name: "ColisPostauxPage", component: ColisPostauxPage },
  { path: "/envoisExpress", name: "EnvoiExpressPage", component: EnvoiExpressPage },
  { path: "/philatelie", name: "PhiLateliePage", component: PhiLateliePage },
  { path: "/posteLettre", name: "PosteLettrePage", component: PosteLettrePage },
  { path: "/serviceDistribution", name: "ServiceDistributionPage", component: ServiceDistributionPage },

  //Services financiers
  { path: "/assurances", name: "Assuranceage", component: AssurancePage },
  { path: "/centreCheque", name: "CentreChequePage", component: CentreChequePage },
  { path: "/caisseNational", name: "CaisseNationalPage", component: CaisseNationalPage },
  { path: "/transfertArgent", name: "TransfertArgentPage", component: TransfertArgentPage },

  //Service publique
  { path: "/servicePublique", name: "ServicePublicPage", component: ServicePublicPage },
  { path: "/servicePoste", name: "ServicePostePage", component: ServicePostePage },
  { path: "/autreService", name: "AutreServicePage", component: AutreServicePage },

  //Service connexe
  { path: "/posteVerte", name: "PosteVertePage", component: PosteVertePage },
  { path: "/posteVoyage", name: "PosteVoyagePage", component: PosteVoyagePage },
  { path: "/produitPartenariat", name: "ProduitPartenariatPage", component: ProduitPartenariatPage },
  
  //Actualite
  { path: "/actualite", name: "ActualitePostePage", component: ActualitePostePage },

  //Mediatheque
  { path: "/mediatheque", name: "MediathequePostePage", component: MediathequePostePage },
  { path: "/mediathequeVideo", name: "MediathequePosteVideoPage", component: MediathequePosteVideoPage },

  { path: "/document", name: "DocumentPostePage", component: DocumentPostePage },

  //Mentions
  { path: "/mentionLegale", name: "MentionLegalPage", component: MentionLegalPage },
  { path: "/donnePersonnelle", name: "DonnePersonnellePage", component: DonnePersonnellePage },
  { path: "/cookies", name: "CooKiePage", component: CooKiePage },

  //Services Détails
  { path: "/produitTransfert", name: "ProduitTransfertPage", component: ProduitTransfertPage },
  { path: "/produitColisPostaux", name: "ProduitColisPostauxPage", component: ProduitColisPostauxPage },
  { path: "/produitEnvoisExpress", name: "ProduitEnvoisExpressPage", component: ProduitEnvoisExpressPage },
  { path: "/produitPosteLettre", name: "ProduitPosteLettrePage", component: ProduitPosteLettrePage },
  { path: "/produitTransportLocation", name: "ProduitTransportLocationPage", component: ProduitTransportLocationPage },

  { path: "/simulation", name: "SimulationPage", component: SimulationPage },


];

const router = createRouter({
  //history: createWebHistory(),
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
