<template>
    <div class="blog-details-wrap ptb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-8 col-lg-12">
                    <div>
                        <div class="post-img" v-if="article?.urlImage">
                            <img :src="getUrlApiForFiles(article.urlImage)" alt="Image" v-if="article?.urlImage">
                            <p v-else class="text-center">Aucune image</p>
                        </div>
                        <div class="post-img" v-if="article?.source">
                            <div class="youtube-video" v-if="article?.source">
                          <iframe
                            :src="getYoutubeUrl(article?.source)"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        </div>
                        <ul class="post-metainfo  list-style">
                            <li><i class="flaticon-user"></i><router-link to="">La Poste du Bénin</router-link></li>
                            <li><i class="flaticon-calendar"></i>{{ format_Date(article?.datePublication) }}</li>
                            <li><i class="flaticon-bubble-chat"></i>No Comment</li>
                        </ul>
                        <h2>{{ article?.titre }}</h2>
                        <h5>{{ article?.sousTitre }}</h5>
                        <div class="post-para" v-html="article?.contenu">
                        </div>
                        <div class="row ">
                            <div class="col-xl-4 col-lg-6 col-md-6" v-for="(articlee, index) in articlees" :key="index">
                                <div class="blog-card style1">
                                    <a :href="getUrlApiForFiles(articlee.type)" target="_blank" data-lightbox="gallery">
                                        <div class="blog-img" >
                                            <img :src="getUrlApiForFiles(articlee.type)" alt="Image" >
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div style="margin-bottom:20px"><a href="https://tawk.to/lapostebenineservice" target="_blank" class="btn btn-sm style1">Discuter avec un agent de La Poste du Bénin</a></div>
                    </div>
                    <div class="post-meta-option" v-if="article?.articletags.length > 0">
                        <div class="row gx-0 align-items-center">
                            <div class="col-md-7 col-12">
                                <div class="post-tag">
                                    <span> <i class="flaticon-supermarket"></i>Tags:</span>
                                    <ul class="tag-list list-style">
                                        <li v-for="articletag in article?.articletags" :key="articletag.id">
                                            <router-link to="">{{ articletag.tag.nom }}</router-link>,
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-5 col-12 text-md-end text-start">
                                <div class="post-share w-100">
                                    <span>Réseaux Sociaux:</span>
                                    <ul class="social-profile style2 list-style">
                                        <li>
                                            <a target="_blank" href="https://facebook.com">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://twitter.com">
                                                <i class="ri-twitter-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://linkedin.com">
                                                <i class="ri-linkedin-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://instagram.com">
                                                <i class="ri-pinterest-fill"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12">
                    <ViewArticleSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide  } from "vue";
import ViewArticleSidebar from '../ViewArticle/ViewArticleSidebar.vue'
import { useRoute } from "vue-router";
import { error, format_Date, getUrlApiForFiles } from "@/utils/utils";
import { Article } from "@/models/Article";
import ApiService from "@/services/ApiService";

export default {
    name: 'PreviewArticle',
    components: {
        ViewArticleSidebar
    },
    setup: () => {
    const route = useRoute();
    const article = ref<Article | null>(null);
    const articles = ref<Array<Article>>([]);
    const categorie = ref(0)
    function getArticle(id: string) {
      return ApiService.get("/preview/article/"+id)
        .then(({ data }) => {
          article.value = data.data; 
          categorie.value = data.data.categorieArticle.id; 
          console.log('article', article.value);
          console.log('categorie', categorie.value)
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    function getYoutubeUrl(source) {
        if (!source) return ''; 
        return `https://www.youtube.com/embed/${source}`;
        }

        const articlees = ref<Array<Article>>([]);   

        function getAllMedia(alias:any) {
        return ApiService.get(`/previewarticle/medias/`+alias)
            .then(({ data }) => {
            articlees.value = data.data;
            console.log(articlees.value);
            return data;
            })
            .catch(({ response }) => {
            error(response.message)
        });
        }

    onMounted(() => {
      const id = route.params.id as string;
      if(id){
        getArticle(id)
      }
      const alias= route.params.id
      console.log('alias', alias)
        getAllMedia(alias);
      console.log('zzzzzzzzzzzzzzzzzzz', categorie.value)

    //   getArticle(id);
    });

    provide('categorie', categorie);

    return { article, getArticle, articles,format_Date, error,
        getUrlApiForFiles,categorie,getYoutubeUrl,articlees, };
  },
}
</script>

<style>
.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}</style>