<template>
    <div 
        @click="scrollToTop()"
        :class="['back-to-top bounce', {'open': isTop}]"
    >
        <i class="ri-arrow-up-s-line"></i>
    </div> 
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";

  export default defineComponent({
    name: 'BackToTop',
    // data (){
    //     return {
    //         isTop: false
    //     }
    // },
    // methods: {
    //     scrollToTop() {
    //         window.scrollTo(0, 0);
    //     },
    // },
    // mounted(){
    //     let that = this
    //     window.addEventListener('scroll', () => {
    //         let scrollPos = window.scrollY
    //         if(scrollPos >= 100){
    //             that.isTop = true
    //         } else {
    //             that.isTop = false
    //         }
    //     })
    // }

    setup() {
     const isTop=ref(false);
    function scrollToTop() {
             window.scrollTo(0, 0);
     }
    onMounted(() => {
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                isTop.value = true
            } else {
                isTop.value = false
            }
        })
    });
      return {isTop,scrollToTop};
    },

});
</script>