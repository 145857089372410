<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="À propos de nous" />
            <SmartBanking />
            
            <OnlineShopping />
            <!-- <AppDownload /> -->
            <!-- <GoalSettings /> -->
            <ServicesComp />
            <!-- <Testimonials /> -->
            <!-- <BankingSecurity /> -->
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import SmartBanking from '../About/SmartBanking'
import ServicesComp from '../About/ServicesComp'
import OnlineShopping from '../About/OnlineShopping'
// import AppDownload from '../About/AppDownload'
// import GoalSettings from '../About/GoalSettings'
// import TestimonialsComp from '../About/TestimonialsComp'
// import BankingSecurity from '../About/BankingSecurity'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'AboutPage',
    components: {
        NavBar,
        PageTitle,
        SmartBanking,
        ServicesComp,
        OnlineShopping,
        // AppDownload,
        // GoalSettings,
        // TestimonialsComp,
        // BankingSecurity,
        FooterComp,
    }
}
</script>